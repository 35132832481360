import React from "react";

const TermsOfUseText = () => {
  return (
    <div style={{ marginTop: "2vw" }}>
      <p>
        <strong>
          Nutzungsbedingungen des virtuellen Showrooms der Jansen AG
        </strong>
      </p>
      <ol>
        <li>
          <p>Vertragsgegenstand und Geltungsbereich</p>
          <p>
            Sie haben sich erfolgreich als Nutzer unseres virtuellen Showrooms
            registriert. Wir, die Jansen AG, Oberriet (SG) („JANSEN“)
            ermöglichen es Ihnen, auf eine neue, interaktive Weise in die
            JANSEN-Produktewelt einzutauchen und durch das Sortiment von
            3D-Modellen zu navigieren. Zusätzlich zum kostenlos zugänglichen
            Bereich, den Sie betreten haben, wird in naher Zukunft eine
            erweiterte Version des virtuellen Showrooms erhältlich sein. Für die
            erweiterte Version ist ein separater Lizenzvertrag abzuschliessen.
          </p>
          <p>
            Sämtliche datenschutzrechtliche Informationen finden Sie in der{" "}
            <a
              href="https://www.jansen.com/de/datenschutz.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutzerklärung
            </a>{" "}
            von JANSEN.
          </p>
          <p>
            Als Nutzer dieses virtuellen Showrooms akzeptieren Sie
            uneingeschränkt die folgenden Nutzungsbedingungen:
          </p>
        </li>
        <li>
          <p>Geistiges Eigentum</p>
          <p>
            Jeglicher Inhalt, sowie sämtliche Features und Funktionen dieses
            virtuellen Showrooms sind das geistige Eigentum von JANSEN oder
            Dritten, die Inhalte oder Produkte im virtuellen Showroom
            bereitstellen. Insbesondere unterliegen die Software, die Codes,
            sämtliche Texte, Bilder, Graphiken, Ton-, Video- und
            Animationsdateien, Button-Icons, Benutzeroberflächen, visuellen
            Oberflächen oder sonstige Werke, sowie das Design, die Struktur,
            Auswahl, Koordination und Anordnung des Inhalts des virtuellen
            Showrooms dem Urheberrecht und anderen Rechten von JANSEN.
          </p>
          <p>
            Sämtliche Markenzeichen, Firmenlogos und Embleme im virtuellen
            Showroom sind markenrechtlich geschützt und dürfen von Ihnen ohne
            die ausdrückliche schriftliche Zustimmung von JANSEN nicht verwendet
            werden, es sei denn, dies ist ausdrücklich anders angegeben. Dies
            gilt sowohl für JANSEN Marken und Logos, als auch für im virtuellen
            Showroom enthaltene Marken, Logos oder Embleme Dritter. Sie sind
            nicht berechtigt, Copyright-Vermerk abzuändern oder zu entfernen.
          </p>
        </li>
        <li>
          <p>Lizenz</p>
          <p>3.1 Lizenzbedingungen</p>
          <p>
            Der Zugriff und die Nutzung des virtuellen Showrooms erfordern die
            Installation einer Software (einschliesslich aller Updates und
            Upgrades und zugehöriger Dokumentation) („Software") auf einem
            Endgerät Ihrer Wahl. Durch das Herunterladen (Download), die
            Installation bzw. den Gebrauch der Software, erklären Sie sich
            insbesondere auch mit den Bestimmungen dieser Ziffer 3 einverstanden
            und sind an diese gebunden.
          </p>
          <p>
            Unter der Voraussetzung der Einhaltung dieser Nutzungsbedingungen,
            sowie der Bezahlung gegebenenfalls anfallender Gebühren, gewährt
            JANSEN Ihnen als Lizenznehmer eine beschränkte, einfache, nicht
            exklusive, nicht übertragbare und nicht unterlizenzierbare Lizenz
            zum ausschliesslich persönlichen, nichtkommerziellen (ausser im
            Zusammenhang mit Produkten von JANSEN) Gebrauch der Software auf
            einem eigenem Endgerät Ihrer Wahl. Im Rahmen dieser Lizenz haben Sie
            das Recht, die Software auf einem unter Ihrer Kontrolle stehenden
            Endgerät zu installieren, in den Arbeitsspeicher zu laden und an
            diesem Standort ausschliesslich für den Zugriff und Besuch des
            virtuellen Showrooms zu nutzen.
          </p>
          <p>
            JANSEN gewährt keine weitergehende Lizenz, insbesondere nicht für
            folgende Handlungen: Reproduktion; Herstellung abgeleiteter Werke;
            Vervielfältigung; Extraktion zur Wiederverwendung; Übertragung zur
            Nutzung in einer anderen Dienstleistung; Inkorporierung von Teilen
            der Software in Ihren eigenen Computerprogrammen; Kopie; Nachahmung;
            Weiter-/Rückentwicklung; Verwendung auf anderen Webseiten;
            Verbindung mit einer anderer Software und/oder anderen Daten;
            Herstellung oder Veröffentlichung einer eigenen Datenbank, welche
            die Produktinformationen beinhaltet; Veränderung; Ausdrucken;
            entgeltliche oder unentgeltliche Zugänglichmachung im Internet, in
            anderen Netzwerken oder auf andere Weise; Vermietung; Verleih;
            Weiterleitung; Vertrieb; Weiterverkauf oder generell eine
            kommerzielle Nutzung der Software, wenn diese nicht im Zusammenhang
            mit Produkten von JANSEN besteht. Sie bestätigen zudem, kein Reverse
            Engineering zu betreiben, die Software zu bearbeiten, zu
            dekompilieren oder zu disassemblieren. Auch die Anstiftung Dritter
            oder Beihilfe zu den obgenannten Handlungen ist untersagt.
          </p>

          <p>3.2 Lizenzgebühren und Zahlungsmodalitäten</p>
          <p>
            Falls Sie die kostenpflichtige erweiterte Version des virtuellen
            Showrooms heruntergeladen haben, wird die geschuldet Lizenzgebühr in
            einem separaten Lizenzvertrag festgelegt.{" "}
          </p>

          <p>3.3 Rechtsverletzungen durch Dritte, Nichtangriffspflicht</p>
          <p>
            Falls Sie davon Kenntnis erhalten, dass ein Dritter
            Immaterialgüterrechte oder andere Rechte an der Software verletzt,
            so haben Sie JANSEN unverzüglich hiervon zu unterrichten.{" "}
          </p>
          <p>
            Sie verpflichten sich, die Immaterialgüterrechte (inkl. geschütztes
            oder nicht geschütztes Knowhow) und andere Rechte von JANSEN an der
            Software oder der JANSEN-Produktewelt bzw. Immaterialgüterrechte
            Dritter, welche Produkte im virtuellen Showroom ausstellen, weder
            selbst anzugreifen noch Dritte beim Angriff zu unterstützen.
          </p>

          <p>3.4 Dauer der Lizenz </p>
          <p>
            Die Rechteeinräumung gemäss dieser Ziffer 3 kann von JANSEN
            jederzeit ohne Angabe von Gründen widerrufen werden. Mit Beendigung
            Ihrer Zugangsberechtigung gemäss Ziffer 8 hiernach erlöschen
            sämtliche von JANSEN eingeräumten Rechte an der Software.{" "}
          </p>

          <p>3.5 Übertragbarkeit / Abtretung </p>
          <p>
            Der Lizenzvertrag oder einzelne Rechte oder Pflichten daraus dürfen
            von Ihnen nur nach vorgängiger schriftlicher Zustimmung von JANSEN
            an Dritte übertragen oder abgetreten werden.{" "}
          </p>

          <p>3.6 Nutzerkonto</p>
          <p>
            Um unseren virtuellen Showroom besuchen zu können, mussten Sie ein
            Nutzerkonto erstellen. Bitte achten Sie darauf, dass alle Ihre
            Angaben (einschliesslich Zahlungs- und Kontaktinformationen) korrekt
            und aktuell sind. Sie sind für alles verantwortlich, was mit Ihrem
            Nutzerkonto geschieht, insbesondere für die Sicherstellung der
            Vertraulichkeit Ihres Nutzerkontos und Passwortes, sowie für die
            Beschränkung des Zugangs zu Ihrem Computer und Ihren mobilen
            Geräten. Lassen Sie Ihr Nutzerkonto von keiner anderen Person nutzen
            und halten Sie Ihren Nutzernamen und Ihr Passwort geheim.
            Informieren Sie JANSEN unverzüglich, wenn Sie davon ausgehen müssen,
            dass ein Dritter möglicherweise Kenntnis von Ihrem Passwort erlangt
            hat oder das Passwort unautorisiert genutzt wird.
          </p>
        </li>

        <li>
          <p>Produkte </p>
          <p>
            Ähnlich wie bei Webseiten, können die Farben der im virtuellen
            Showroom gezeigten Produkte aus technischen Gründen auf Ihrem
            Endgerät oder auf einem von Ihnen gefertigten Ausdruck von den
            tatsächlichen Verhältnissen abweichen.{" "}
          </p>
          <p>
            Die Umgebungen, in denen die Produkte präsentiert werden, sind rein
            dekorativ und beispielhaft. Mögliche technische oder rechtliche
            Einschränkungen und Vorgaben sind vom Nutzer für jeden konkreten
            Fall abzuklären und wurden von JANSEN bei der Darstellung nicht
            berücksichtigt. JANSEN Mitarbeiter stehen Ihnen für sämtliche Fragen
            über das hinterlegte Kontaktformular gerne zur Verfügung.
          </p>
          <p>
            Änderungen der beschriebenen Modelle bleiben vorbehalten und JANSEN
            behält sich das Recht vor, jedes gezeigte Produkt ohne vorgängige
            Ankündigung zu ändern.{" "}
          </p>
        </li>

        <li>
          <p>Verkaufsbedingungen</p>
          <p>
            Alle über den virtuellen Showroom getätigten Käufe unterliegen den{" "}
            <a
              href="https://www.jansen.com/de/jansen-konzern/allgemeine-vertragsbedingungen-der-jansen-ag.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Allgemeinen Vertragsbedingungen
            </a>{" "}
            von JANSEN.
          </p>
        </li>

        <li>
          <p>Gewährleistung und Haftung</p>
          <p>Die Installation der Software erfolgt auf Ihr Risiko.</p>
          <p>
            Die Software für den virtuellen Showroom wird sorgfältig unterhalten
            und die Informationen zu den ausgestellten Produkten stets auf dem
            neusten Stand gehalten. Damit die Software Updates durchführen kann,
            müssen Sie eine Internetverbindung herstellen. JANSEN übernimmt
            keine ausdrückliche oder stillschweigende Zusicherung oder Garantie
            für die Fehlerfreiheit, Genauigkeit oder Vollständigkeit der
            enthaltenen Informationen und Angaben. Bezüglich der Zusicherung,
            Garantie oder Haftung für ausgestellte Produkte gelten die{" "}
            <a
              href="https://www.jansen.com/de/jansen-konzern/allgemeine-vertragsbedingungen-der-jansen-ag.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Allgemeinen Vertragsbedingungen
            </a>{" "}
            von JANSEN.
          </p>

          <p>
            JANSEN übernimmt keine Gewähr für einen störungsfreien und
            ununterbrochenen Zugang zum virtuellen Showroom. JANSEN kann den
            Zugriff auf die Software von Zeit zu Zeit für Updates,
            Wartungsarbeiten (geplant oder ungeplant), Erweiterungen, Upgrades,
            Optimierungen oder Bugfixes oder zur Bewahrung oder Verbesserung der
            Sicherheit einschränken und/oder aussetzen. Die Verfügbarkeit des
            virtuellen Showrooms kann aus Gründen, die nicht im Einflussbereich
            von JANSEN liegen, nicht jederzeit garantiert werden.
          </p>
          <p>
            JANSEN schliesst, soweit rechtlich zulässig, jegliche Haftung für
            Schäden aus, die direkt oder indirekt aus (a) dem Zugriff oder der
            Unmöglichkeit des Zugriffs auf den virtuellen Showroom, oder (b)
            dessen Benutzung oder (c) aus anderen Rechtsgründen entstehen,
            insbesondere für indirekte Schäden, Folgeschäden und entgangener
            Gewinn. JANSEN übernimmt keine Haftung für Verluste im Zusammenhang
            mit Viren, die Ihr Endgerät oder System nach der Nutzung des
            virtuellen Showrooms oder dem Herunterladen von Inhalten infizieren
            könnten.{" "}
          </p>
          <p>
            JANSEN behält sich das Recht vor, jederzeit und ohne Vorankündigung
            Änderungen am virtuellen Showroom oder dessen Inhalt vorzunehmen und
            übernimmt keine Haftung für Folgen, die sich aus solchen Änderungen
            ergeben könnten.
          </p>
          <p>
            Aus Gründen der Benutzerfreundlichkeit, befinden sich im virtuelle
            Showroom Links zu externen Webseiten Dritter, auf deren Inhalt wir
            keinen Einfluss haben. JANSEN hat die externen Webseiten bei der
            Verlinkung geprüft. Eine dauerhafte Überprüfung dieser Seiten ist
            jedoch nicht zumutbar und JANSEN übernimmt keine Gewähr für externe
            Webseiten, den störungsfreien Zugang oder deren Inhalt. Jegliche
            Haftung für direkte oder indirekte Schäden aus dem Zugriff oder der
            Benutzung der externen Webseiten sind, soweit rechtlich zulässig,
            ausgeschlossen.{" "}
          </p>
          <p>
            Bitte beachten Sie, dass sich einige Informationen auf Produkte
            beziehen können, die nicht in allen Ländern erhältlich sind. Bitte
            erkundigen Sie sich bei der nächstgelegenen JANSEN Niederlassung
            über die in Ihrem Land verfügbaren Produkte.
          </p>
          <p>
            Nutzer, die von ausserhalb der Schweiz auf die Software zugreifen,
            sind für die Einhaltung aller geltenden lokalen Gesetze
            verantwortlich. Der Zugriff auf die Software aus einem Land, in
            welchem der Inhalt als gesetzeswidrig betrachtet wird oder werden
            könnte, ist nicht gestattet.
          </p>
        </li>

        <li>
          <p>Änderungen dieser Nutzungsbedingungen</p>
          <p>
            JANSEN behält sich das Recht vor, diese Nutzungsbedingungen
            jederzeit zu ändern. Als Nutzer des virtuellen Showrooms sind Sie an
            solche Änderungen gebunden und es liegt in Ihrer Verantwortung,
            eventuelle Änderungen, welche seit Ihrem letzten Besuch vorgenommen
            wurden, zur Kenntnis zu nehmen.
          </p>
        </li>

        <li>
          <p>Beendigung</p>
          <p>
            Ihre Zugangsberechtigung endet jederzeit, sobald Sie Ihr Nutzerkonto
            löschen. JANSEN behält sich das Recht vor, diese Nutzungsbedingungen
            jederzeit mit sofortiger Wirkung zu kündigen, einen Nutzer ohne
            Angabe von Gründen von der Software auszuschliessen oder andere
            geeignete Massnahmen zu ergreifen.{" "}
          </p>
          <p>
            Mit der Löschung des Nutzerkontos oder Kündigung erlischt
            automatisch und unverzüglich das Recht zur Nutzung der Software und
            Sie sind zur sofortigen Löschung und Deinstallation der Software auf
            Ihrem Endgerät verpflichtet. Zudem endet Ihr Recht zur Nutzung der
            Software automatisch, ohne dass eine entsprechende Kündigung
            erforderlich ist, wenn Sie diese Nutzungsbedingungen nicht
            einhalten.
          </p>
          <p>
            Im Falle einer Löschung des Nutzerkontos oder Kündigung bleiben alle
            unbezahlten Lizenzgebühren geschuldet und bereits bezahlte
            Lizenzgebühren werden nicht zurückerstattet.{" "}
          </p>
        </li>

        <li>
          <p>Anwendbares Recht und Gerichtsstand</p>
          <p>9.1 Salvatorische Klausel</p>
          <p>
            Sollten Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise
            nichtig oder unwirksam sein oder werden oder Lücken enthalten, so
            berührt dies die Gültigkeit der übrigen Bestimmungen nicht. Anstelle
            dieser nichtigen, unwirksamen oder fehlenden Regelung gilt eine
            wirksame, dem hypothetischen Willen der Parteien sowie Sinn und
            Zweck der entfallenden bzw. fehlenden Regelung die am nächsten
            kommende Bestimmung als vereinbart.
          </p>
          <p>9.2 Anwendbares Recht</p>
          <p>
            Diese Nutzungsbedingungen unterliegen dem materiellen Schweizer
            Recht, unter Ausschluss des Kollisionsrechts und des internationalen
            Kaufrechts (CISG).{" "}
          </p>
          <p>9.3 Erfüllungsort und Gerichtsstand</p>
          <p>
            Für die Beurteilung aller sich aus oder im Zusammenhang mit diesen
            Nutzungsbedingungen bzw. der Nutzung des virtuellen Showrooms
            ergebenden Streitigkeiten sind die ordentlichen Gerichte am Sitz von
            JANSEN ausschliesslich zuständig.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermsOfUseText;
