import React, { Component } from "react";

import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ImprintLink } from "@solidwhite/vis-base";

const mapStateToProps = (state) => ({
  application: state.application,
});

class Application extends Component {
  render() {
    return (
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <div className={"container-fluid justify-content-left mt-5"}>
          <div className="card-narrow">
            <div className={"d-flex mb-4"}>
              <h2 className={"page-title ml-0"}>Application</h2>
            </div>

            <div className="card card-wide rounded shadow mb-3">
              <div className="row m-0">
                <div className="col m-0 p-0">
                  <img
                    src={process.env.PUBLIC_URL + "/Jansen_Banner_CP.jpg"}
                    className=" rounded-top"
                    alt="Application"
                  />
                </div>
              </div>

              <div className="p-5">
                <div className="row mb-4">
                  <div className="col">
                    <h4>Virtual Showroom</h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <p className="text-muted mb-4">
                      With its virtual showroom, Jansen is offering another
                      digitisation module. Here, you will find the complete
                      product overview as well as information on design options
                      and security applications.
                    </p>

                    <a
                      href="https://webshowroom.jansen.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        type="button"
                        id="downloadAppButton"
                        className="btn btn-primary"
                      >
                        Start Virtual Showroom
                      </button>
                    </a>
                    <p className="text-muted mt-4">
                      The product overview contains series and products, divided
                      into windows, doors, façades as well as folding and
                      sliding doors. The key points for the various target
                      groups are summarised and allow users to look into topics
                      in greater detail via sectional drawings, brochures or by
                      forwarding them to the product pages. The design
                      configurator offers a choice of different handles, hinges,
                      surfaces and materials. These changes are applied directly
                      to the way in which the element is displayed. The Security
                      Applications section explains in a short, simple and
                      entertaining way how fire protection and burglar
                      resistance are tested and classified and which products
                      can be assigned to security areas, for example.
                    </p>
                  </div>
                </div>

                <div className="row mt-5 mb-5">
                  <div className="col">
                    <video
                      style={{ objectFit: "contain" }}
                      width="50%"
                      controls
                      preload="metadata"
                    >
                      <source
                        src={
                          process.env.PUBLIC_URL +
                          "/Jansen_Showroom_Tutorial_mitUntertitel_new.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ImprintLink />
      </div>
    );
  }
}

Application.propTypes = {
  application: PropTypes.object,
};

export default connect(mapStateToProps, null)(Application);
