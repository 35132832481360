const reducer = (state = {}, action) => {
  switch (action.type) {
    case "INIT_APPLICATION":
      return {
        ...state,
        headerImage: action.data.headerImage,
        downloadLink: action.data.downloadLink,
        downloadLinkMac: action.data.downloadLinkMac,
        downloadText: action.data.downloadText,
        downloadTextMac: action.data.downloadTextMac,
        headerText: action.data.headerText,
        descriptionText: action.data.descriptionText,
        additionalText: action.data.additionalText,
      };

    default:
      return state;
  }
};

export default reducer;
