import React from 'react';

import { ImprintAndLegal } from './';

export default {
    list: {
        path: 'imprint',
        //icon: 'info',
        title: 'Imprint - Privacy Policy - Legal',
        private: false,
        restricted: "",
        //level: 0,
        component: (props) =>
            <ImprintAndLegal />
        ,
    },
};