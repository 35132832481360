const reducer = (state = {}, action) => {

    switch (action.type) {

        case 'INIT_IMPRINT':

            return state;
        
        default:
        
            return state;
    
    }

}

export default reducer;