import React from "react";
import TermsOfUseText from "./TermsOfUseText";

const TermsOfUse = () => {
  return (
    <>
      <div>
        <p>Please find the terms here:</p>
        <ul>
          <li>
            <a
              href="https://www.solidwhite.de/vishow_endnutzerbedingungen.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              VISHOW App End User Terms of SOLID WHITE.
            </a>
          </li>

          <li>
            <a
              href="https://www.jansen.com/de/jansen-konzern/allgemeine-vertragsbedingungen-der-jansen-ag.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Allgemeine Vertragsbedingungen - Jansen AG: Jansen.
            </a>
          </li>
        </ul>
      </div>

      <TermsOfUseText />
    </>
  );
};

export default TermsOfUse;
