import React from "react";

import { Application } from ".";

export default {
  list: {
    path: "downloadapp",
    icon: "VDC_IconFont_Download",
    title: "Application",
    private: false,
    restricted: "",
    level: 0,
    component: (props) => <Application />,
  },
};
