import React from "react";

const ImprintText = () => {
  return (
    <div>
      <p>
        Jansen AG
        <br />
        Industriestrasse 34
        <br />
        9463 Oberriet SG
      </p>
      <p>
        Tel.: +41 71 763 91 11
        <br />
        E-Mail: <a href="mailto:info@jansen.com">info@jansen.com</a>
      </p>
      <p>UID-Nummer: CHE-102.513.848</p>
    </div>
  );
};

export default ImprintText;
