import React from "react";

const PrivacyText = () => {
  return (
    <div>
      <p>Please find the information here:</p>
      <ul>
        <li>
          <a
            href="https://www.jansen.com/de/datenschutz.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutz - Jansen Gruppe mit Hauptsitz in Oberriet SG: Jansen
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PrivacyText;
